<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';
import error403 from '../utility/error-403';

import {
  required,
  requiredIf,
  email,
  sameAs,
  minLength,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "User registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, error403 },
  props:{
    id:[Number,null],
    rights: Array
  },
  data() {
    return {
      title: "User registration",
      items: [
        {
          text: "Company",
          href: "/"
        },
        {
          text: "Users",
          href: "/company/users"
        },
        {
          text: "User",
          active: true
        }
      ],
      form: {
        id_company:           "",
        id_employee:          "",
        id_role:              "",
        de_email:             "",
        de_password:          "",
        de_rePassword:        "",
      },
      submitted: false,
      employees: [],
      roles: [],

      tableData: [],
      tableName: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100],
      filter: null,
      filterOn: [],
      sortBy: "id_company",
      sortDesc: false,
      selectMode: 'single',
      fields: [],
      selected: [],
      okPassword:true,
      changePass: false,
      validToken: true,
      validAccess: true,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  validations: {
    form: {
      id_company:             { required: requiredIf(function(){return !this.$route.params.id;}) },
      id_employee:            { required },
      id_role:                { required },
      de_email:               { required, email },
      de_password:            {
        required: requiredIf(function(){return !this.$route.params.id;}),
        minLength: minLength(5),
        maxLength: maxLength(16),
      },
      de_rePassword:          { required: requiredIf(function(){return !this.$route.params.id;}), sameAsPassword: sameAs("de_password") },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows  = this.items.length;

    this.getRoles();

    if( this.$route.params.id ){
      this.getUser();
    }else{
      this.changePass = !this.changePass;
      this.getFields();
      this.getEmployeesWithoutUser();
    }
  },
  methods: {
    async getRoles(){
      Swal.fire({
        title: "Loading roles.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
         window.serverPath+'dist/mvw/controllers/users.php',
        {
          params: {
            request: 'getUsersRoles',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.roles = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    async getFields(){
      let app = this;
      axios
      .get(
         window.serverPath+'dist/mvw/controllers/employees.php',
        {
          params: {
            request: 'getFields',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.fields = response.data;
            app.sortBy = response.data[0].key;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load data! Please load this module again.", "error");
        }
      }).catch(e =>{
        Swal.close();
        if( e.response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if( e.response.status == 404){ app.$router.push({ name: 'Error-404' }); }
      });
    },
    async getEmployeesWithoutUser(){
      Swal.fire({
        title: "Loading employees.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
         window.serverPath+'dist/mvw/controllers/employees.php',
        {
          params: {
            request: 'getEmployeesWithoutUser',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            this.tableData = response.data;
            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    async getUser(){
      Swal.fire({
        title: "Loading user data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
         window.serverPath+'dist/mvw/controllers/users.php',
        {
          params: {
            request: 'getUser',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.user = response.data;

            this.form.id_role         = app.user.id_role;
            this.form.id_company      = app.user.id_company;
            this.form.id_employee     = app.user.id_employee;
            this.form.id_user         = app.user.id_user;
            this.form.de_email        = app.user.de_email;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load user data! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveUser(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error && this.okPassword ){
        Swal.fire({
          title: "Saving user.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post( window.serverPath+'dist/mvw/controllers/users.php', {
          request: 'saveUser',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "Your user is correct "+action+"!", "success");
            app.$router.push({ name: 'users' });
          }
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(item) {
      console.log(item[0]);
      if(item.length == 0){
        this.form.id_company  = "";
        this.form.de_email    = "";
        this.form.id_employee = "";
      }else{
        this.form.id_company  = item[0].id_company;
        this.form.id_employee = item[0].id_employee;
        this.form.de_email    = item[0].de_employeeCompanyEmail;
      }
    },
    validatePassword(event){
      const containsUppercase = /[A-Z]/.test(event.target.value);
      const containsLowercase = /[a-z]/.test(event.target.value);
      const containsNumber = /[0-9]/.test(event.target.value);
      const containsSpecial = /[#?!@$%^&*-]/.test(event.target.value);

      if( this.changePass ){
        if( this.form.de_password != ""){
          if( containsUppercase && containsLowercase && containsNumber && containsSpecial ){
            this.okPassword = true;
          }else{
            this.okPassword = false;
          }
        }else{
          this.okPassword = false;
        }
      }
      //return this.okPassword;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div v-if="validAccess" class="card-body">
            <form class="needs-validation" @submit.prevent="saveUser">
              <div class="row">
                <div class="col-lg-4">
                  <h4 class="card-title">User Data</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="de_email">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_email.$error }"
                          name="de_email"
                          id="de_email"
                          v-model="form.de_email"
                          value=""
                          placeholder="User email (company email)"
                        >
                        <div v-if="submitted && $v.form.de_email.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_email.required">Employee name is required.</span>
                        </div>
                        <div v-if="$route.params.id">
                          <span>This field mus be edited from Employees module, by editing the employee this email belongs.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_role">Role</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_role.$error }"
                          name="id_role"
                          id="id_role"
                          v-model="form.id_role"
                        >
                          <option value="">SELECT USER ROLE</option>
                          <option v-for="(role, index) in roles" :key="index" :value="role.id_role">{{role.nm_role}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_role.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_role.required">Site is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_password">Password</label>
                        <input
                          v-model="form.de_password"
                          type="password"
                          name="de_password"
                          class="form-control"
                          :class="{ 'is-invalid': (submitted && $v.form.de_password.$error) || (!okPassword) }"
                          placeholder="Password"
                          v-on:keypress="validatePassword"
                          :disabled="!changePass"
                        />
                        <div v-if="(submitted && $v.form.de_password.$error) || (!okPassword)" class="invalid-feedback">
                          <span v-if="!$v.form.de_password.required">This value is required.</span>
                          <span v-if="!$v.form.de_password.minLength">Password must be at least 6 characters.</span>
                          <span v-if="!$v.form.de_password.maxLength">Password must be 16 characters maximum.</span>
                          <span v-if="!okPassword">
                            Password must content at least 1 Capital, 1 minus, 1 digit and at least 1 of those simbols #?!@$%^&*-
                          </span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_password">Repeat Password</label>
                        <input
                          v-model="form.de_rePassword"
                          type="password"
                          name="de_rePassword"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_rePassword.$error }"
                          placeholder="Re-Type Password"
                          :disabled="!changePass"
                        />

                        <div
                          v-if="submitted && $v.form.de_rePassword.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.de_rePassword.required">Password is required.</span>
                          <span
                            v-else-if="!$v.form.de_rePassword.sameAsPassword"
                          >This value should be the same.</span>
                        </div>
                      </div>

                      <div class="form-group" v-if="this.$route.params.id">
                        <b-form-checkbox v-model="changePass" switch class="mb-1">
                          <label>Change password?</label>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>

                <!--
                <div class="col-lg-4" v-if="$route.params.id">
                  <h4 class="card-title">Security</h4>
                  <div class="row">
                    <div class="col-md-12">
                      

                      
                    </div>
                  </div>
                </div>
                -->

                <div class="col-lg-8" v-if="!$route.params.id">
                  <h4 class="card-title">Select the employee to become a user</h4>
                  
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Show&nbsp;
                          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>

                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table
                      :items="tableData"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      :hover="true"
                      selectable
                      :select-mode="selectMode"
                      @row-selected="onRowSelected"
                    >
                      <template #cell(actions)="{ rowSelected }">
                        <template v-if="rowSelected">
                          <i class="fas fa-check btn-sm btn-primary"></i> <b>SELECTED</b>
                        </template>
                        <template v-else>
                          <i class="fas fa-check btn-sm btn-light"></i>
                        </template>
                      </template>
                      <!--
                      <template v-slot:cell(actions)="row">
                        
                        
                         info(row.item, row.index, $event.target) 
                      </template>
                      -->
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <!--
                <div class="col-lg-4">
                  <h4 class="card-title">Organizational Data</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="id_role">Role</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_role.$error }"
                          name="id_role"
                          id="id_role"
                          v-model="form.id_role"
                        >
                          <option value="">SELECT USER ROLE</option>
                          <option v-for="(role, index) in roles" :key="index" :value="role.id_role">{{role.nm_role}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_role.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_role.required">Site is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_employee">Employee</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_employee.$error }"
                          name="id_employee"
                          id="id_employee"
                          v-model="form.id_employee"
                        >
                          <option value="">SELECT EMPLOYEE PROFILE</option>
                          <option v-for="(employee, index) in employees" :key="index" :value="employee.id_employee">{{employee.nm_employee}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_employee.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_employee.required">Department is required.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                -->
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE USER</button>
                    <router-link to="/company/users" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-else class="card-body">
            <div class="row">
              <div class="col-sm-12 text-center">
                <error403></error403>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .form-control:disabled{
    background-color: #ecf0f1 !important;
  }
</style>